import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { OverflowContainer } from "@circle/kip-components";

import styles from "./plantsSettingsList.module.scss";

const PlantsSettingsList = () => {
    const { trigger }               = useTrigger();
    const { translate }             = useTranslation();
    const [item, setItem]           = useState(null);
    const { plants, selectedPlant } = useBranch({
        plants:        ["settingsPlants"],
        selectedPlant: ["selectedPlant"]
    });

    const onSelectPlant = plantId => {
        setItem(plantId);
        trigger("setPlant", plantId);
    };

    useEffect(() => {
        trigger("search", "");
    }, []);

    useEffect(() => {
        setItem(selectedPlant?.id);
    }, [selectedPlant]);

    return (
        <div className={styles.container}>
            <div className={styles.searchContainer}>
                <h6>{translate("plants.all")}</h6>
            </div>

            <OverflowContainer className={styles.contentContainer}>
                { plants.map((plant, i) =>
                    <div
                        key={i}
                        tabIndex={0}
                        role="button"
                        className={resolveClassNames(styles.item, item === plant.id ? styles.isActive : "")}
                        id={plant.id}
                        onClick={() => onSelectPlant(plant.id)}
                    >
                        <span>{translate(plant.name)}</span>
                    </div>
                )}
            </OverflowContainer>
        </div>
    );
};

export { PlantsSettingsList };
